import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import slimming from "../../assets/odchudzanie.webp"

function SlimmingPage() {
  return (
    <>
      <Helmet>
        <title>Jak skutecznie schudnąć? | Nasze terapie odchudzające</title>
        <meta
          name="description"
          content="Poznaj skuteczne metody odchudzania wspierane terapiami biorezonansowymi. Dowiedz się, jak testy i terapie mogą pomóc w walce z nadwagą i otyłością."
        />
        <meta
          name="keywords"
          content="odchudzanie, biorezonans, terapie, testy na pasożyty, metabolizm, zdrowie, nadwaga, otyłość"
        />
        <meta
          property="og:title"
          content="Jak skutecznie schudnąć? | Nasze terapie odchudzające"
        />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          {/* Main Content */}
          <article className="left-side xl:w-[60%]">
            {/* Main Heading */}
            <header>
              <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
                Jak skutecznie schudnąć?
              </h1>
            </header>

            {/* Intro Paragraph */}
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nadwaga i otyłość to poważne problemy zdrowotne, które mogą
              prowadzić do chorób serca, cukrzycy i problemów ze stawami. Nasze
              terapie biorezonansowe wspierają proces odchudzania poprzez
              identyfikację i eliminację przeszkód w utracie wagi.
            </p>

            {/* Services Introduction */}
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Oferujemy następujące usługi:
            </p>

            {/* Services List */}
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Testy:</h2>
                <p className="text-slate-600 xl:px-3">
                  Testy na pasożyty i grzyby, które mogą wpływać na metabolizm i
                  apetyt.
                </p>
              </li>
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Terapie:</h2>
                <ul className="text-slate-600 xl:px-3">
                  <li>- Poprawa metabolizmu tłuszczów</li>
                  <li>- Poprawa metabolizmu węglowodanów</li>
                  <li>- Regulacja hormonalna</li>
                  <li>- Wyciszenie układu nerwowego</li>
                </ul>
              </li>
            </ul>

            {/* Image and Additional Info */}
            <section className="photoAndTextContainer mt-5 mb-10">
              <figure className="w-full p-5">
                <img
                  src={slimming}
                  alt="Kobieta mierzy talię, wspierająca odchudzanie"
                  loading="lazy"
                />
                <figcaption className="text-slate-600 tracking-wide mt-2">
                  Monitorowanie obwodu talii to kluczowy krok w procesie
                  odchudzania.
                </figcaption>
              </figure>
              <div className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Otyłość prowadzi do poważnych problemów zdrowotnych, takich
                  jak choroby serca, cukrzyca i problemy ze stawami. Może
                  również wpływać na samoocenę i jakość życia. Właściwe
                  zarządzanie masą ciała poprzez zdrową dietę, aktywność
                  fizyczną i nasze terapie może poprawić zdrowie i samopoczucie.
                </p>
                <p className="mt-5">
                  Nasze terapie biorezonansowe pomagają w skutecznym
                  odchudzaniu, identyfikując przyczyny problemów z wagą i
                  dostosowując terapię do indywidualnych potrzeb.
                </p>
              </div>
            </section>
          </article>

          {/* Sidebar */}
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h3 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h3>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/alergie">
                    <p className="hover:font-medium">Usuwanie alergii</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default SlimmingPage;
