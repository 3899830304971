import React from 'react';
import { Helmet } from 'react-helmet';
import Main from '../components/offerPage/Main';
import AddressTopInfo from '../components/mainPage/AddressTopInfo';

function Offer() {
  return (
    <>
      <Helmet>
        <title>Nasza Oferta - BioRezo-Med | Biorezonans Łódź</title>
        <meta name="description" content="Oferujemy kompleksowe usługi biorezonansu w Łodzi. Sprawdź naszą ofertę testów i terapii, w tym testy alergiczne, boreliozę, pasożyty, terapie odchudzające i wiele innych." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="Nasza Oferta - BioRezo-Med | Biorezonans Łódź" />
        <meta property="og:description" content="Oferujemy kompleksowe usługi biorezonansu w Łodzi. Sprawdź naszą ofertę testów i terapii, w tym testy alergiczne, boreliozę, pasożyty, terapie odchudzające i wiele innych." />
        <meta property="og:image" content="..\..\public\favicon.ico"/>
        <meta property="og:url" content="https://biorezo-med.com/oferta" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://biorezo-med.com/oferta" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <AddressTopInfo />
      <Main />
    </>
  );
}

export default Offer;
