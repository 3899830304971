import React, { useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";

// Import the image correctly
import family from "../../assets/heroFamily.webp";

function Hero() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section name="home" className="heroContainer w-full h-85% bg-[#f5fbf9] flex opinions-bg hero-border">
      <main className="xl:w-[80%]  rounded-3xl flex p-2 xl:p-0 mx-auto max-w-[1720px]">
        <div className="left-text xl:w-[50%] text-[#033860] xl:mt-[10%] xl:ml-[10%]">
          {/* Header text */}
          <h1 className="font-bold text-5xl xl:text-7xl p-2">
            <span className="block text-[#05B2DC] font-thin">Powitaj</span>
            <span className="block py-3 text-white">Dobre samopoczucie!</span>
          </h1>

          {/* Description */}
          <p className="xl:w-[75%] text-xl xl:py-4 p-2 leading-relaxed text-white">
            Zapraszamy do naszego gabinetu biorezonansu BioRezo-Med,
            zlokalizowanego w Łodzi na Widzewie. Nasze terapie biorezonansem
            pozwolą Ci odmienić Twoje życie. Pozbędziesz się uciążliwych
            dolegliwości, wyeliminujesz alergie i poczujesz się pełen energii i
            siły. Pomożemy Ci odzyskać równowagę i dobre samopoczucie.
            Skorzystaj z naszych usług i poczuj się jak nowonarodzony!
          </p>

          {/* Buttons */}
          <div className="flex justify-evenly xl:w-[60%] mt-5">
            <ScrollLink to="offer" smooth={true} duration={500}>
              <button
                className="border border-[#F9F871] p-3 xl:p-5 bg-[#F9F871] rounded-full text-black shadow-black
                font-bold hover:bg-transparent hover:text-[#F9F871] flex text-xl button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
                aria-label="Przejdź do naszej oferty"
              >
                Nasza Oferta
              </button>
            </ScrollLink>
            <ScrollLink to="contact" smooth={true} duration={800}>
              <button
                className="border border-[#F9F871] p-3 xl:p-5 bg-[#033860] rounded-full text-[#f5fbf9] shadow-black
                font-bold hover:text-black hover:border-none hover:bg-[#f5fbf9] flex text-xl button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
                aria-label="Umów się na wizytę"
              >
                Umów się na wizytę!
              </button>
            </ScrollLink>
          </div>
        </div>

        {/* Image */}
        <div className="right-image w-[50%] mt-auto py-1 hidden xl:block">
          <img
            src={family}
            alt="Szczęśliwa rodzina w dobrym samopoczuciu, ojciec, matka i córka uśmiechnięci razem"
            className="rounded-xl"
            loading="lazy"
          />
        </div>
      </main>
    </section>
  );
}

export default Hero;
