import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as OuterLink } from "react-router-dom";

import logo from "../../assets/logo-z-bio.webp";

function TopNavigation() {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    // whole nav
    <nav
      className="w-[100%] flex bg-white border-b"
      aria-label="Główna nawigacja"
    >
      {/* container for logo, menu and button */}
      <div className="w-[80%] mx-auto flex justify-between py-3 max-w-[1720px]">
        {/* logo */}
        <div className="w-[185px] md:w-[185px] py-2 flex align-middle">
          <img src={logo} alt="Logo Biorezonans" />
        </div>
        {/* menu */}
        <div className="w-[60%] mr-[4%] my-auto">
          <ul className="hidden xl:flex justify-around py-4">
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="aboutBiorezonans" smooth={true} duration={500}>
                Co to biorezonans
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="offer" smooth={true} duration={500}>
                Oferta
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="apparatus" smooth={true} duration={500}>
                Aparatura
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="opinions" smooth={true} duration={500}>
                Opinie Klientów
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="forKids" smooth={true} duration={500}>
                Dla Dzieci
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <ScrollLink to="contact" smooth={true} duration={500}>
                Kontakt
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </ScrollLink>
            </li>
            <li className="hover:cursor-pointer hover-underline-animation font-light group transition duration-300">
              <OuterLink to="/cennik">
                Cennik
                <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
              </OuterLink>
            </li>
          </ul>
        </div>
        {/* button */}
        <ScrollLink to="contact" smooth={true} duration={500}>
          <button
            className="p-3 md:p-5 bg-[#F9F871] rounded-full text-black shadow-black
        font-bold hover:bg-transparent hover:bg-white xl:flex text-lg button-shadow hidden ease-in duration-200 hover:-translate-y-1 hover:scale-105"
          >
            Umów się na wizytę!
          </button>
        </ScrollLink>

        {/* burger menu buttons */}
        <div className="block xl:hidden mr-4 mt-2" onClick={handleClick}>
          {!nav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000000"
              className="w-8 h-8"
              aria-label="Otwórz menu nawigacji"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000000"
              className="w-8 h-8"
              aria-label="Zamknij menu nawigacji"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
      </div>
      {/* hidden nav */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute bg-zinc-100 w-full px-8 mt-24 burgermenu pb-8"
        }
      >
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <ScrollLink to="home" smooth={true} duration={500}>
            Home
          </ScrollLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <ScrollLink to="offer" smooth={true} duration={500}>
            Oferta
          </ScrollLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <ScrollLink to="opinions" smooth={true} duration={500}>
            Opinie Klientów
          </ScrollLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <ScrollLink to="forKids" smooth={true} duration={500}>
            Dla Dzieci
          </ScrollLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <ScrollLink to="contact" smooth={true} duration={500}>
            Kontakt
          </ScrollLink>
        </li>
        <li className="border-b-2 border-zinc-300 w-full p-4 hover:cursor-pointer hover-underline-animation">
          <OuterLink to="/cennik" rel="noopener noreferrer">
            Cennik
          </OuterLink>
        </li>
      </ul>
    </nav>
  );
}

export default TopNavigation;
