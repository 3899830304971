import React from "react";

function AboutBiorezonans() {
  return (
    <section
      name="aboutBiorezonans"
      className="offerPage w-full flex pt-5 bg-[#f5fbf9]"
    >
      <div className="offerContainer xl:w-[80%] mx-auto p-2 flex m-10 flex-col max-w-[1720px]">
        <div data-aos="fade-right" className="offerTitle">
          <h1 className="md:text-7xl text-6xl md:pt-7 md:p-4 p-2 font-thin text-[#033860]">
            Czym jest biorezonans?
          </h1>
          <p
            className="md:p-4 p-2 text-slate-600 xl:columns-2 first-line:tracking-widest
  first-letter:text-7xl first-letter:font-bold 
  first-letter:mr-3 first-letter:float-left"
          >
            Biorezonans BioRezo-Med w Łodzi, Widzew, to wyjątkowe połączenie
            najnowszych osiągnięć nauki i medycyny. Nasze urządzenie
            elektroniczne umożliwia diagnostykę i terapię przy pomocy drgań
            elektromagnetycznych, co oznacza, że jest to bezboleśnie i
            bezinwazyjne rozwiązanie. Biorezonans pozwala na wykrycie obecności
            w organizmie szkodliwych czynników, takich jak pasożyty, grzyby,
            bakterie, wirusy, toksyny, reakcji na alergeny czy elektrosmog.
            Zjawisko fizyczne zwane rezonansem elektromagnetycznym jest podstawą
            działania biorezonansu. Aparat Bicom Optima jest nowoczesnym
            biorezonansem, który umożliwia elektropunkturę i biorezonans
            pasywny. Jeśli w organizmie występują częstotliwości obciążającej
            substancji, oddziałując na nią sygnałem o odpowiedniej
            częstotliwości, można zauważyć efekt rezonansu. Biorezonans
            charakteryzuje się wysoką skutecznością, sięgającą ok. 95%. Organizm
            ludzki, tkanki i narządy mają swoje unikalne spektrum drgań
            elektromagnetycznych. Kiedy organizm jest zdrowy, te drgania są
            harmonijne, ale w przypadku choroby ulegają one zaburzeniom i
            pojawiają się drgania patologiczne. Biorezonans Bicom Optima pozwala
            na wpływanie na pole elektromagnetyczne i wykazywanie drgań
            patologicznych poprzez przekazywanie organizmowi lustrzanej fali o
            takiej samej częstotliwości. W ten sposób możliwe jest wzmacnianie
            naturalnych sił organizmu oraz zwalczanie patogenów.
          </p>
        </div>
        <div className="w-[100px] h-[6px] rounded-full bg-[#033860] mx-auto mt-10"></div>
      </div>
    </section>
  );
}

export default AboutBiorezonans;
