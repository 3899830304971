import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import alergie from "../../assets/alergie.webp"

function AllergiesPage() {
  return (
    <>
      <Helmet>
        <title>Jak walczyć z alergiami? | Biorezo-med</title>
        <meta
          name="description"
          content="Dowiedz się, jak walczyć z alergiami dzięki naszym testom diagnostycznym i terapiom. Zidentyfikuj alergeny i skorzystaj z naszej pomocy w leczeniu alergii."
        />
        <meta
          name="keywords"
          content="alergie, testy alergiczne, odczulanie, diagnostyka alergii"
        />
       <meta property="og:title" content="Jak walczyć z alergiami? | Biorezo-med" />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <article className="left-side xl:w-[60%]">
            <header>
              <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
                Jak walczyć z alergiami?
              </h1>
            </header>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Alergia to nadmierna reakcja układu immunologicznego na
              substancje, które normalnie są dla większości ludzi bezpieczne.
              Wiele osób doświadcza różnego rodzaju objawów, takich jak zatkany
              nos, trudności z oddychaniem, problemy z trawieniem, swędząca
              wysypka, bóle głowy, bezsenność i problemy z koncentracją.
              Zidentyfikowanie przyczyny reakcji alergicznej może być trudne,
              ponieważ objawy nie zawsze występują natychmiast po kontakcie z
              alergenem.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nasza pomoc obejmuje następujące elementy:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Testy:</h2>
                <ul>
                  <li>- Na alergeny wziewne (pyłki, sierść, roztocza)</li>
                  <li>
                    - Na nietolerancje pokarmowe (zboża, nabiał, orzechy,
                    warzywa, owoce, mięso, ryby)
                  </li>
                  <li>
                    - Na dodatki do żywności E (konserwanty, barwniki,
                    polepszacze smaku, emulgatory itp.)
                  </li>
                  <li>- Na jad pszczoły, osy i komara</li>
                </ul>
              </li>
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">
                  Odczulanie:
                </h2>
                <p className="text-slate-600 xl:px-3">
                  Bezinwazyjne odczulanie na ponad 100 alergenów.
                </p>
              </li>
            </ul>
            <div className="photoAndTextContainer mt-5 mb-10">
              <figure className="w-full p-5">
                <img
                  src={alergie}
                  alt="Kobieta z katarem siennym trzymająca chusteczkę, siedząca na krześle w swetrze"
                />
                <figcaption>
                  Objawy alergii mogą obejmować katar sienny i inne
                  dolegliwości.
                </figcaption>
              </figure>
              <div className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Alergia może mieć poważne konsekwencje dla codziennego życia,
                  ponieważ może powodować ciągłe dolegliwości i ograniczać
                  normalne funkcjonowanie. Alergicy często cierpią z powodu
                  bólów głowy, trudności z oddychaniem, swędzącej wysypki i
                  innych objawów, co może wpłynąć na jakość snu i wprowadzać
                  zaburzenia w codziennym życiu.
                </p>
                <p className="mt-5">
                  Czasami lekarzom trudno jest zidentyfikować alergen, co może
                  prowadzić do braku unikania go przez pacjęta, co w
                  konsekwencji przełoży się na nawracające reakcje alergiczne i
                  nieustający stres emocjonalny. W rezultacie, alergia może mieć
                  negatywny wpływ na jakość życia, samopoczucie i funkcjonowanie
                  codzienne.
                </p>
              </div>
            </div>
          </article>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h2>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default AllergiesPage;
