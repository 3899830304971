import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";

import kid from "../../assets/happyChild.webp";

function ForKids() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section name="forKids" className="w-full bg-[#f5fbf9] flex">
      <main className="w-full bg-[#033860] flex flex-col xl:flex-row p-2 md:p-0 ">
        <div className="max-w-[1720px] flex mx-auto justify-center align-middle">
        <div
          data-aos="zoom-in-up"
          data-aos-delay="100"
          className="left-text xl:w-[50%] text-[#f5fbf9] md:mt-[10%] md:ml-[10%] "
        >
          <h2 className="md:text-7xl text-6xl p-2 font-thin text-[#05B2DC]">
            Gabinet Przyjazny Dzieciom
          </h2>
          <h1 className="font-bold md:text-7xl text-5xl py-3 p-2">
            Bezpieczna Terapia dla Najmłodszych
          </h1>
          <p className="md:py-4 p-2">
            Biorezonans to metoda, która idealnie sprawdza się w leczeniu dzieci
            dzięki swojej bezinwazyjności. Terapeuta stosuje specjalną matę lub
            metalowe pałeczki, które dziecko trzyma w rękach – wszystko odbywa
            się bez bólu i stresu, co czyni tę terapię wyjątkowo komfortową dla
            najmłodszych pacjentów.
          </p>
          <div className="flex justify-between md:w-[70%] my-5">
            <Link to="services" smooth={true} duration={500}>
              <button
                className="border border-[#05B2DC] p-3 md:p-5 bg-[#05B2DC] rounded-full text-[#f5fbf9] shadow-black
                font-bold hover:bg-transparent hover:bg-[#033860] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
              >
                Poznaj Naszą Ofertę
              </button>
            </Link>
            <Link to="contact" smooth={true} duration={500}>
              <button
                className="border border-[#05B2DC] p-3 md:p-5 bg-[#033860] rounded-full text-[#f5fbf9] shadow-black
                font-bold hover:text-[#033860] hover:bg-[#f5fbf9] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
              >
                Umów Wizytę
              </button>
            </Link>
          </div>
        </div>
        <figure
          className="my-auto p-3 md:pt-7 hidden xl:flex"
          data-aos="zoom-in-up"
          data-aos-delay="100"
        >
          <img
            className="rounded-t-2xl w-full h-auto"
            src={kid}
            alt=""
            loading="lazy"
          />
          <figcaption className="sr-only"></figcaption>
        </figure>
        </div>
      </main>
    </section>
  );
}

export default ForKids;
