import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

function OfferPanel({ name, desc, image, alt, link }) {
  return (
    <div className="offer-panel lg:w-[28%] flex flex-col text-center mt-8 bg-white rounded-2xl shadow-md pb-5">
      <figure>
        <img
          className="rounded-t-2xl w-full h-auto"
          src={image}
          alt={alt}
          loading="lazy"
        />
        <figcaption className="sr-only">{name}</figcaption>
      </figure>
      <h2 className="text-black text-3xl md:text-4xl p-4">{name}</h2>
      <p className="text-black p-2">{desc}</p>
      <RouterLink to={link} className="block mt-5 mx-auto">
        <button
          className="bg-[#F9F871] rounded-full text-black shadow-md mx-auto p-3 px-5 font-bold hover:bg-[#F9F871] hover:text-black flex items-center justify-center text-lg transition-transform duration-200 transform hover:-translate-y-1 hover:scale-105"
          aria-label={`Czytaj więcej o ${name}`}
        >
          Czytaj dalej{" "}
          <FaArrowRight className="ml-2 text-md" aria-hidden="true" />
        </button>
      </RouterLink>
    </div>
  );
}

export default OfferPanel;
