import React, { useEffect } from "react";
import { Link } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";

import woman1 from "../../assets/woman1.webp";
import woman2 from "../../assets/woman2.webp";
import woman3 from "../../assets/woman3.webp";

function Opinions() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section className="w-full bg-[#f5fbf9] flex -z-100 opinions-bg opinions-border heroContainer" id="opinions">
      <div className="mx-auto w-full  rounded-3xl flex p-2 md:p-0 max-w-[1720px]">
        <div className="opinionsContainer xl:w-[80%] mx-auto">
          <header data-aos="fade-right" className="opinionsTitle xl:w-[50%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 md:p-4 p-2 mt-20 font-thin text-white">
              Zaufało nam już wielu!
            </h1>
            <p className="md:p-4 p-2 text-white">
              Biorezonans to przede wszystkim zadowoleni klienci, którzy często
              po wielu latach niepowodzeń pozbywają się swoich uciążliwych
              problemów.
            </p>
          </header>
          <div className="opinionsElements w-full flex justify-around xl:flex-row flex-col">
            <article
              data-aos="zoom-in-up"
              data-aos-delay="100"
              className="opinionsElement xl:w-[30%] text-center p-4 flex flex-col"
            >
              <div className="rounded-full h-[250px] w-[250px] mx-auto overflow-hidden flex items-center justify-center">
                <img
                  src={woman1}
                  alt="Zdjęcie portretowe kobiety"
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>

              <div className="opinionsElementText">
                <p
                  className="p-2 text-white first-line:tracking-widest
                first-letter:text-7xl first-letter:font-bold first-letter:text-white
                first-letter:mr-3 first-letter:float-left"
                >
                  Gabinet biorezonansu to rewelacyjne miejsce dla osób
                  zmagających się z alergiami. Po kilku sesjach zauważyłam
                  znaczną poprawę i brak uciążliwych objawów!
                </p>
              </div>
            </article>
            <article
              data-aos="zoom-in-up"
              data-aos-delay="300"
              className="opinionsElement xl:w-[30%] text-center p-4 flex flex-col"
            >
              <div className="rounded-full h-[250px] w-[250px] mx-auto overflow-hidden flex items-center justify-center">
                <img
                  src={woman2}
                  alt="Zdjęcie portretowe kobiety"
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>
              <div className="opinionsElementText">
                <p
                  className="p-2 text-white first-line:tracking-widest
                first-letter:text-7xl first-letter:font-bold first-letter:text-white
                first-letter:mr-3 first-letter:float-left w-full"
                >
                  Mój wybór przy rzuceniu palenia padł na terapie biorezonansowe
                  i jestem bardzo zadowolona z efektów. W końcu pozbyłam się
                  nałogu!
                </p>
              </div>
            </article>
            <article
              data-aos="zoom-in-up"
              data-aos-delay="600"
              className="opinionsElement xl:w-[30%] text-center p-4 flex flex-col"
            >
              <div className="rounded-full h-[250px] w-[250px] mx-auto overflow-hidden flex items-center justify-center">
                <img
                  src={woman3}
                  alt="Zdjęcie portretowe kobiety"
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
              </div>
              <div className="opinionsElementText">
                <p
                  className="p-2 text-white first-line:tracking-widest
                first-letter:text-7xl first-letter:font-bold first-letter:text-white
                first-letter:mr-3 first-letter:float-left"
                >
                  Diagnoza boreliozy potwierdzona, a leczenie w gabinecie
                  biorezonansu daje mi prawdziwą nadzieję na wyleczenie. Jestem
                  pod wrażeniem skuteczności tej metody.
                </p>
              </div>
            </article>
          </div>
          <div className="opinionsButton w-full flex p-4 mt-10">
            <div className="mx-auto">
              <Link to="contact" smooth={true} duration={500}>
                <button
                  className="border p-3 md:p-6 bg-[#F9F871] rounded-full text-black shadow-black
                  font-bold hover:bg-transparent hover:border-none hover:text-[#F9F871] flex text-lg shadow-xs button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105"
                >
                  Skontaktuj się z nami!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Opinions;
