import React from "react";
import OfferPanel from "./OfferPanel";
import { Link as RouterLink } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

import alergie from "../../assets/alergie.webp"
import odchudzanie from "../../assets/odchudzanie.webp"
import infekcje from "../../assets/infekcje.webp"
import palenie from "../../assets/rzucanie_palenia.webp"
import borelioza from "../../assets/kleszcz.webp"
import zmeczenie from "../../assets/zmeczenie.webp"
import problemy_skorne from "../../assets/problemy_skorne.webp"
import organy from "../../assets/narzady.webp"


function Main() {
  return (
    <main className="opinions-bg offer-page w-full flex flex-col bg-[#f5fbf9]">
      <header className="header-section flex flex-col py-8">
        <RouterLink
          to="/"
          className="back-link flex items-center text-white mx-auto xl:mx-1 px-3 mt-2 font-bold"
        >
          <FaArrowLeft className="mr-2" aria-hidden="true" />
          Strona główna
        </RouterLink>
        <h1 className="page-title font-bold text-5xl md:text-7xl text-white mx-auto">
          Nasza oferta
        </h1>
        <div className="underline mx-auto bg-[#F9F871] w-16 h-1 rounded-full mt-4"></div>
      </header>

      <section className="offer-panels w-full flex flex-wrap justify-around p-3">
        <OfferPanel
          name="Wsparcie odchudzania"
          desc="Przyspieszenie utraty wagi podczas stosowania diety dzięki detoksykacji układu wchłaniania, wsparcie metabolizmu oraz regulacja hormonalna."
          image={odchudzanie}
          alt="Wsparcie odchudzania - Slim woman measuring her thin waist"
          link="/oferta/odchudzanie"
        />
        <OfferPanel
          name="Przewlekłe infekcje"
          desc="Jeżeli cierpisz na nieustające przeziębienia i grypy, nawet poza sezonem chorobowym, Twój organizm potrzebuje wzmocnienia, co zapewnią nasze terapie."
          image={infekcje}
          alt="Przewlekłe infekcje - Sick man with thermometer"
          link="/oferta/infekcje"
        />
        <OfferPanel
          name="Usuwanie alergii"
          desc="Alergie to uciążliwy problem dotykający wiele osób które często spotykają się ze stwierdzeniem, że nic nie da się zrobić. Koniec z tym! Sprawdź naszą ofertę dla alergików i odetchnij z ulgą!"
          image={alergie}
          alt="Usuwanie alergii - Allergies treatment"
          link="/oferta/alergie"
        />
        <OfferPanel
          name="Rzucanie palenia"
          desc="Terapia antynikotynowa jest wsparciem dla osoby, która zdecydowała się rzucić palenie. Ma na celu wygaszanie głodu nikotynowego, który powinien ustąpić w ciągu 2-12 godzin po zabiegu"
          image={palenie}
          alt="Rzucanie palenia - Smoking cessation therapy"
          link="/oferta/rzucanie-palenia"
        />
        <OfferPanel
          name="Borelioza"
          desc="Choroba o szerokim spektrum obiawów której często nie jesteśmy świadomi. Głęboko ukryte bakterie sukcesywnie osłabiają nasz organizm. Sprawdź jak można je pokonać!"
          image={borelioza}
          alt="Borelioza - Lyme disease treatment"
          link="/oferta/borelioza"
        />
        <OfferPanel
          name="Przewlekłe zmęczenie"
          desc="W dobie dzisiejszego tempa życia wiele osób cierpi na przewlekłe zmęczenie. Brak snu i duże wydatki energetyczne w pracy osłabiają nasz organizm. Wzmocnij się z nami!"
          image={zmeczenie}
          alt="Przewlekłe zmęczenie - Chronic fatigue"
          link="/oferta/przewlekle-zmeczenie"
        />
        <OfferPanel
          name="Problemy skórne"
          desc="Problemy skórne często występujące u dzieci (ale nie tylko!) są bagatelizowane przez lekarzy co prowadzi do zaniedbań ich leczenia. Pozbądź się ich na zawsze!"
          image={problemy_skorne}
          alt="Problemy skórne - Skin issues"
          link="/oferta/problemy-skorne"
        />
        <OfferPanel
          name="Dysfunkcje organów"
          desc="Problemy z trawieniem, ciągłe zmęczenie lub nadciśnienie to tylko niektóre z objawów osłabionych narządów naszego organizmu. Pozwól mu stanąć na nogi!"
          image={organy}
          alt="Dysfunkcje organów - Organ dysfunction"
          link="/oferta/dysfunkcje-organow"
        />
      </section>
    </main>
  );
}

export default Main;
