import React from 'react';
import { Helmet } from 'react-helmet';
import AddressTopInfo from '../components/mainPage/AddressTopInfo';
import Pricing from '../components/pricingsPage/Pricing';

function Pricings() {
  return (
    <>
      <Helmet>
        <title>Cennik - BioRezo-Med | Biorezonans Łódź</title>
        <meta name="description" content="Sprawdź nasz cennik za usługi biorezonansu w Łodzi. Oferujemy przejrzyste ceny na testy alergiczne, boreliozę, pasożyty, terapie odchudzające i inne usługi zdrowotne." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta property="og:title" content="Cennik - BioRezo-Med | Biorezonans Łódź" />
        <meta property="og:description" content="Sprawdź nasz cennik za usługi biorezonansu w Łodzi. Oferujemy przejrzyste ceny na testy alergiczne, boreliozę, pasożyty, terapie odchudzające i inne usługi zdrowotne." />
        <meta property="og:image" content="..\..\public\favicon.ico" />
        <meta property="og:url" content="https://biorezo-med.com/cennik" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://biorezo-med.com/cennik" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <AddressTopInfo />
      <Pricing />
    </>
  );
}

export default Pricings;
