import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import organy from "../../assets/narzady.webp"

function OrgansPage() {
  return (
    <>
      <Helmet>
        <title>Wzmocnienie organów wewnętrznych | Biorezo-med</title>
        <meta
          name="description"
          content="Odkryj, jak biorezonans może wspierać funkcjonowanie organów wewnętrznych, poprawiając ich równowagę i eliminując toksyny. Sprawdź nasze testy i terapie na zdrowie organów."
        />
        <meta
          name="keywords"
          content="wzmocnienie organów, biorezonans, testy równowagi narządów, terapie organów, zdrowie organów, toksyny"
        />
       <meta property="og:title" content="Wzmocnienie organów wewnętrznych | Biorezo-med" />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <article className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <section className="left-side xl:w-[60%]">
            <header>
              <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
                Wzmocnienie organów wewnętrznych
              </h1>
            </header>
            <section className="content">
              <p className="md:p-4 p-2 text-slate-600 tracking-wide">
                Biorezonans wspiera funkcjonowanie organów wewnętrznych,
                pomagając utrzymać homeostazę i optymalny stan zdrowia. Ta
                technologia wspiera prawidłowe działanie organów takich jak
                nerki, żołądek, płuca czy jelita, które mogą ulegać zaburzeniom
                lub zanieczyszczeniom na skutek przewlekłego stresu. Biorezonans
                pomaga eliminować toksyny i poprawiać funkcjonowanie organów, co
                może przynieść ulgę w dolegliwościach takich jak bóle, wypadanie
                włosów, kaszel, katar, wzdęcia, obniżona odporność czy problemy
                skórne.
              </p>
              <p className="md:p-4 p-2 text-slate-600 tracking-wide">
                Nasza oferta obejmuje:
              </p>
              <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
                <li>
                  <h3 className="font-bold xl:px-3 text-slate-800">Testy:</h3>
                  <ul className="text-slate-600 xl:px-3">
                    <li>- Równowagi narządów</li>
                  </ul>
                </li>
                <li>
                  <h3 className="font-bold xl:px-3 text-slate-800">Terapie:</h3>
                  <p className="text-slate-600 xl:px-3">
                    Wzmacniające organy oraz oczyszczające je z toksyn.
                  </p>
                </li>
              </ul>
              <figure className="photoAndTextContainer mt-5 mb-10">
                <img
                  src={organy}
                  alt="Kobieta cierpiąca na problemy z organami wewnętrznymi"
                  className="w-full h-auto"
                  loading="lazy"
                />
                <figcaption className="text-slate-600 text-center mt-2">
                  Brak równowagi organów wewnętrznych może prowadzić do
                  poważnych problemów zdrowotnych, takich jak bóle, zaburzenia
                  trawienia, obniżona odporność czy problemy skórne. Biorezonans
                  może pomóc przywrócić równowagę i poprawić ogólne
                  samopoczucie.
                </figcaption>
              </figure>
            </section>
          </section>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <header>
                <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                  Zobacz także:
                </h2>
                <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              </header>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/borelioza">
                    <p className="hover:font-medium">Borelioza</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </article>
      </main>
      <Footer />
    </>
  );
}

export default OrgansPage;
