import React from "react";

function PricingElement({ name, price }) {
  return (
    <div className="w-full flex">
      <div className="w-full mx-auto text-center flex justify-between items-center text-white border-b py-4 px-2">
        <p className="text-left flex-1 text-base md:text-lg lg:text-2xl">
          {name}
        </p>
        <p className="text-base md:text-lg lg:text-2xl ml-2">{price} zł</p>
      </div>
    </div>
  );
}

export default PricingElement;
