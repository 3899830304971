import React from 'react';

function Title({ title }) {
  return (
    <section className='flex flex-col items-center py-8'>
      <h1 className='font-bold text-4xl md:text-6xl text-white mb-4'>
        {title}
      </h1>
      <div className='w-12 h-1 bg-[#F9F871] rounded-full mb-8' />
    </section>
  );
}

export default Title;
