import React from 'react';
import { Helmet } from 'react-helmet';
import AboutBiorezonans from '../components/mainPage/AboutBiorezonans';
import AddressTopInfo from '../components/mainPage/AddressTopInfo';
import Apparatus from '../components/mainPage/Apparatus';
import Contact from '../components/mainPage/Contact';
import Footer from '../components/mainPage/Footer';
import ForKids from '../components/mainPage/ForKids';
import Hero from '../components/mainPage/Hero';
import Offer from '../components/mainPage/Offer';
import Opinions from '../components/mainPage/Opinions';
import TopNavigation from '../components/mainPage/TopNavigation';

function MainPage() {
  return (
    <div>
      <Helmet>
        <title>Biorezonans Łódź - BioRezo-Med</title>
        <meta name="description" content="Biorezonans Łódź Widzew. Zapraszamy na testy alergiczne, na boreliozę, bakterie, wirusy i pasożyty oraz terapie antynikotynowe." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="..\..\public\favicon.ico" />
        <link rel="apple-touch-icon" href="..\..\public\favicon.ico" />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700&display=swap" rel="stylesheet" />
        <meta property="og:title" content="Biorezonans Łódź - BioRezo-Med" />
        <meta property="og:description" content="Biorezonans Łódź Widzew. Testy alergiczne, borelioza, bakterie, wirusy, pasożyty, terapie antynikotynowe." />
        <meta property="og:image" content="..\..\public\favicon.ico"/>
        <meta property="og:url" content="https://biorezo-med.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <AddressTopInfo />
      <TopNavigation />
      <Hero />
      <AboutBiorezonans />
      <Offer />
      <Apparatus />
      {/* <Motto /> */}
      <Opinions />
      <ForKids />
      <Contact />
      <Footer />
    </div>
  );
}

export default MainPage;
