import React, { useEffect } from "react";
import { Link as OuterLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

import Aos from "aos";
import "aos/dist/aos.css";

import borelioza from "../../assets/kleszcz.webp";
import nikotyna from "../../assets/rzucanie_palenia.webp";
import alergie from "../../assets/alergie.webp";
import infekcje from "../../assets/infekcje.webp";

function Offer() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section name="offer" className="offerPage w-full flex pt-5 bg-[#f5fbf9]">
      <div className="offerContainer w-full xl:w-[80%] mx-auto p-2 max-w-[1720px]">
        <div data-aos="fade-right" className="offerTitle">
          <h1 className="md:text-7xl text-5xl md:pt-7 md:p-4 p-2 font-thin text-[#033860]">
            W końcu poczuj się dobrze!
          </h1>
          <p className="md:p-4 p-2 text-slate-600 xl:w-[60%]">
            Nasza szeroka oferta testów oraz terapii pozwoli na szybkie wykrycie
            źródła problemu, wyeliminowanie go i doprowadzenie pacjenta do pełni
            sił.
          </p>
        </div>

        <div className="offerElements w-full flex flex-wrap justify-center xl:justify-between">
          {/* Element 1 */}
          <div
            data-aos="zoom-in-up"
            className="offerElement xl:w-[23%] w-[90%] sm:w-[45%] text-center p-4 flex flex-col justify-between xl:border-r border-[#033860] mb-6"
          >
            <div className="h-[200px] mx-auto">
              <img
                src={borelioza}
                alt="Kleszcz siedzący na kwiatku."
                className="rounded-lg w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="offerElementText flex-grow">
              <h3 className="md:text-2xl text-xl md:pt-7 md:p-4 p-2 text-[#033860]">
                Borelioza
              </h3>
              <p className="p-2">
                Terapie których celem jest zniwelowanie skutków oraz usunięcie
                przyczyny infekcji.
              </p>
            </div>
            <OuterLink to="/oferta/borelioza">
              <p className="text-[#033860] flex justify-center items-end h-full">
                <span className="flex font-bold">
                  Czytaj dalej <FaArrowRight className="ml-1 mt-1" />
                </span>
              </p>
            </OuterLink>
          </div>

          {/* Element 2 */}
          <div
            data-aos="zoom-in-up"
            data-aos-delay="200"
            className="offerElement xl:w-[23%] w-[90%] sm:w-[45%] text-center p-4 flex flex-col justify-between xl:border-r border-[#033860] mb-6"
          >
            <div className="h-[200px] mx-auto">
              <img
                src={nikotyna}
                alt="Mężczyzna przecinający papierosa nożyczkami."
                className="rounded-lg w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="offerElementText flex-grow">
              <h3 className="md:text-2xl text-xl md:pt-7 md:p-4 p-2 text-[#033860]">
                Antynikotynowa
              </h3>
              <p className="p-2">
                Wygaszanie głodu nikotynowego, który powinien ustąpić w ciągu
                2-12 godzin po zabiegu.
              </p>
            </div>
            <OuterLink to="/oferta/rzucanie-palenia">
              <p className="text-[#033860] flex justify-center items-end h-full">
                <span className="flex font-bold">
                  Czytaj dalej <FaArrowRight className="ml-1 mt-1" />
                </span>
              </p>
            </OuterLink>
          </div>

          {/* Element 3 */}
          <div
            data-aos="zoom-in-up"
            data-aos-delay="400"
            className="offerElement xl:w-[23%] w-[90%] sm:w-[45%] text-center p-4 flex flex-col justify-between xl:border-r border-[#033860] mb-6"
          >
            <div className="h-[200px] mx-auto">
              <img
                src={alergie}
                alt="Kobieta cierpiąca na infekcję i mająca katar."
                className="rounded-lg w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="offerElementText flex-grow">
              <h3 className="md:text-2xl text-xl md:pt-7 md:p-4 p-2 text-[#033860]">
                Alergie
              </h3>
              <p className="p-2">
                Testy pozwalające wykryć alergen oraz terapie które pomagają
                pozbyć się alergii.
              </p>
            </div>
            <OuterLink to="/oferta/alergie">
              <p className="text-[#033860] flex justify-center items-end h-full">
                <span className="flex font-bold">
                  Czytaj dalej <FaArrowRight className="ml-1 mt-1" />
                </span>
              </p>
            </OuterLink>
          </div>

          {/* Element 4 */}
          <div
            data-aos="zoom-in-up"
            data-aos-delay="600"
            className="offerElement xl:w-[23%] w-[90%] sm:w-[45%] text-center p-4 flex flex-col justify-between mb-6"
          >
            <div className="h-[200px] mx-auto">
              <img
                src={infekcje}
                alt="Chory mężczyzna z katarem pod kocem na kanapie."
                className="rounded-lg w-full h-full object-cover"
                loading="lazy"
              />
            </div>
            <div className="offerElementText flex-grow">
              <h3 className="md:text-2xl text-xl md:pt-7 md:p-4 p-2 text-[#033860]">
                Infekcje
              </h3>
              <p className="p-2">
                Leczenie przewlekłych chorób infekcyjnych zarówno wirusowych jak
                i bakteryjnych.
              </p>
            </div>
            <OuterLink to="/oferta/infekcje">
              <p className="text-[#033860] flex justify-center items-end h-full">
                <span className="flex font-bold">
                  Czytaj dalej <FaArrowRight className="ml-1 mt-1" />
                </span>
              </p>
            </OuterLink>
          </div>
        </div>

        <div className="offerButton w-full flex p-4 mt-10">
          <div className="mx-auto">
            <OuterLink to="/oferta">
              <button className="p-3 md:p-6 bg-[#033860] rounded-full text-[#f5fbf9] shadow-black font-bold hover:bg-transparent hover:text-[#033860] flex text-lg button-shadow ease-in duration-200 hover:-translate-y-1 hover:scale-105">
                Nasza Pełna Oferta
              </button>
            </OuterLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Offer;
