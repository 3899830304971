import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import aparat from "../../assets/aparat.webp";

function Apparatus() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <section
      name="apparatus"
      className="offerPage w-full flex pt-5 bg-[#f5fbf9]"
    >
      <div
        className="offerContainer xl:w-[80%] mx-auto p-2 flex m-10 max-w-[1720px]"
        data-aos="zoom-in-up"
        data-aos-delay="100"
      >
        {/* Tekst po lewej stronie */}
        <div data-aos="fade-right" className="offerTitle xl:w-[50%]">
          <h1 className="md:text-7xl text-6xl md:pt-7 md:p-4 p-2 font-thin text-[#033860]">
            Nasza aparatura
          </h1>
          <p className="md:p-4 p-2 text-slate-600">
            W naszym gabinecie korzystamy z aparatu BICOM Optima, produkowanego
            przez renomowaną niemiecką firmę REGUMED® GmbH. Urządzenie to
            spełnia normy sprzętu medycznego zgodnie z EN ISO 13485:2003+AC:2009
            i posiada aktualną gwarancję producenta. BICOM Optima to
            najnowocześniejsze rozwiązanie diagnostyczno-terapeutyczne,
            umożliwiające szeroki zakres terapii elektromagnetycznych drgań
            biorezonansowych. Aparat wyposażony jest w funkcje diagnostyczne
            oraz pięć modułów terapeutycznych, co pozwala na precyzyjne i
            skuteczne leczenie.
          </p>
        </div>

        {/* Obraz po prawej stronie */}
        <div
          className="w-[50%] hidden xl:flex"
          data-aos="zoom-in-up"
          data-aos-delay="100"
        >
          <figure className="h-[200px] my-auto w-full">
            <img
              src={aparat}
              alt="Aparatura do biorezonansu Bicom optima"
              className="rounded-lg w-full h-full object-cover border"
              loading="lazy"
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

export default Apparatus;
