import React from "react";
import AddressTopInfo from "../mainPage/AddressTopInfo";

import Footer from "../mainPage/Footer"

function TestServicesPage() {
  return (
    <>
      <AddressTopInfo />
      <div className="w-full flex">
        <div className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <div className="left-side xl:w-[60%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
              What Is An Orthodontist?
            </h1>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              An orthodontist specializes in fixing problems that happen when
              your teeth are out of alignment. Straight teeth are easier to keep
              clean, which lowers your risk for cavities and gum disease. They
              also help your jaw work efficiently so you can avoid joint and
              muscle problems.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Our orthodontist treats problems such as:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <div className="xl:flex">
                  <h3 className="font-bold xl:px-3 text-slate-800">Overbites:</h3>
                  <p className="text-slate-600">
                    When you have an overbite, your front upper teeth cover your
                    lower teeth when you bite down.
                  </p>
                </div>
              </li>
              <li>
                <div className="xl:flex">
                  <h3 className="font-bold xl:px-3 text-slate-800">Underbites:</h3>
                  <p className="text-slate-600">
                    If you have an underbite, your lower teeth cover your front
                    upper teeth when you bite down.
                  </p>
                </div>
              </li>
              <li>
                <div className="xl:flex">
                  <h3 className="font-bold xl:px-3 text-slate-800">Overcrowding:</h3>
                  <p className="text-slate-600">
                    This condition happens when there are too many teeth in the
                    same area of the jaw.
                  </p>
                </div>
              </li>
              <li>
                <div className="xl:flex">
                <h3 className="font-bold xl:px-3 w-full text-slate-800">
                      Tooth spacing problems:
                    </h3> 
                  <p className="text-slate-600">
                    You may have a gap between two teeth that are next to each
                    other. This problem can occur if you lose a tooth and do not
                    replace it with an artificial one — the remaining teeth on
                    either side may tilt towards the open space.
                  </p>
                </div>
              </li>
            </ul>
            <div className="photoAndTextContainer mt-5 mb-10">
              <div className="w-full p-5">
                <img
                  src="https://media.istockphoto.com/id/503865898/photo/slim-woman-measuring-her-thin-waist.jpg?s=612x612&w=0&k=20&c=QjyNiQzZKIo5R2oypxEzoT4v_WShUFwv7qM6mxVWL9M="
                  alt="placeholder"
                />
              </div>
              <div className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Orthodontics may be a part of your care for other dental
                  issues. If you lose teeth due to gum disease or cavities, your
                  remaining teeth may shift out of place. Straightening them
                  with braces before receiving other treatments, such as dental
                  implants, helps you achieve the best possible results. Read
                  more about our dental medicine services.
                </p>
                <p className="mt-5">
                  Many different types of appliances, both fixed and removable,
                  are used to help move teeth, retrain muscles and affect the
                  growth of the jaws.
                </p>
              </div>
            </div>
          </div>
          <div className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10  hidden">
            <div className="w-[90%] p-8">
              <h3 className="md:text-4xl text-3xl p-2 font-thin text-white">DentiCare Services</h3>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>General Dentistry</p>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>Radiography</p>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>Dental Pediatric</p>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>Whitening</p>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>Dental Pediatric</p>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <p>Whitening</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TestServicesPage;
