import MainPage from './pages/MainPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Pricings from './pages/Pricings';
import ScrollToTop from './components/scrollToTop';
import Offer from './pages/Offer';
import TestServicesPage from './components/servicesPages/testServicesPage';
import SlimmingPage from './components/servicesPages/SlimmingPage';
import TiredPage from './components/servicesPages/TiredPage';
import InfectionPage from "./components/servicesPages/InfectionPage"
import AllergiesPage from "./components/servicesPages/AllergiesPage"
import SmokingPage from "./components/servicesPages/SmokingPage"
import BoreliosisPage from "./components/servicesPages/BoreliosisPage"
import SkinPage from "./components/servicesPages/SkinPage"
import OrgansPage from "./components/servicesPages/OrgansPage"


function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop />
    <Routes>  
      <Route index element={<MainPage/>}></Route>
      <Route path='/' element={<MainPage/>}></Route>
      <Route path='/home' element={<MainPage/>}></Route>
      <Route path='/cennik' element={<Pricings />}></Route>
      <Route path='/oferta' element={<Offer />}></Route>
      <Route path='/oferta/testService' element={<TestServicesPage />}></Route>
      <Route path='/oferta/odchudzanie' element={<SlimmingPage />}></Route>
      <Route path='/oferta/przewlekle-zmeczenie' element={<TiredPage />}></Route>
      <Route path='/oferta/infekcje' element={<InfectionPage />}></Route>
      <Route path='/oferta/alergie' element={<AllergiesPage />}></Route>
      <Route path='/oferta/rzucanie-palenia' element={<SmokingPage />}></Route>
      <Route path='/oferta/borelioza' element={<BoreliosisPage />}></Route>
      <Route path='/oferta/problemy-skorne' element={<SkinPage />}></Route>
      <Route path='/oferta/dysfunkcje-organow' element={<OrgansPage />}></Route>



    </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
