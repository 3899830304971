import React from "react";
import { Link as OuterLink } from "react-router-dom";
import PricingElement from "./PricingElement";
import Title from "./Title";
import { FaArrowLeft } from "react-icons/fa";

function Pricing() {
  return (
    <div className="bg-[#033860] w-full flex flex-col  pb-10">
      {/* Navigation Button */}
      <OuterLink to="/home">
        <button
          aria-label="Powrót do strony głównej"
          className="border border-[#05B2DC] p-3 md:p-8 bg-[#05B2DC] rounded-full text-white shadow-black
            font-bold hover:bg-transparent hover:text-[#05B2DC] hover:bg-white xl:flex text-3xl button-shadow hidden
            fixed top-[80%] left-8 animate-bounce"
        >
          <FaArrowLeft />
        </button>
      </OuterLink>

      {/* Section: Testy diagnostyczne */}
      <section>
        <Title title="Testy diagnostyczne" />
        <div className="w-full mx-auto max-w-5xl">
          <PricingElement
            name='150 Testów alergicznych - wziewne, pokarmowe, dodatki do żywności "E"'
            price="180"
          />
          <PricingElement
            name="100 Testów alergicznych - nietolerancje pokarmowe"
            price="100"
          />
          <PricingElement name='Testy alergiczne - dodatki "E" ' price="50" />
          <PricingElement name="Pasożyty" price="70" />
          <PricingElement name="Grzyby" price="50" />
          <PricingElement name="Borelioza i choroby odkleszczowe" price="80" />
          <PricingElement
            name="Test niedoborów minerałów i witamin"
            price="50"
          />
          <PricingElement name="Bakterie" price="80" />
          <PricingElement name="Wirusy" price="50" />
          <PricingElement name="Test narządów" price="50" />
          <PricingElement name="Test obciążeń metalami ciężkimi" price="60" />
        </div>
      </section>

      {/* Section: Terapie */}
      <section>
        <Title title="Terapie" />
        <div className="w-full mx-auto max-w-5xl">
          <PricingElement
            name="Terapia pocovidowa (wzmocnienie organizmu po wirusie)"
            price="50"
          />
          <PricingElement
            name="Terapia wspomagająca leczenie cukrzycy"
            price="150"
          />
          <PricingElement
            name="Terapia pobniżajaca poziom cholesterolu"
            price="150"
          />
          <PricingElement
            name="Terapia lecząca zespół jelita drażliwego"
            price="150"
          />
          <PricingElement name="Terapia wspierająca odchudzanie" price="150" />
          <PricingElement name="Terapia antynikotynowa" price="150" />
          <PricingElement
            name="Terapie zdrowotne (patogeny)"
            price="50 - 150"
          />
          <PricingElement name="Terapie odczulające" price="50" />
          <PricingElement name="Terapie wzmacniające narządy" price="50" />
        </div>
      </section>

      {/* Section: Pakiety */}
      <section>
        <Title title="Pakiety" />
        <div className="w-full mx-auto max-w-5xl">
          <PricingElement
            name="Pakiet przedszkolak (alergeny, pasożyty, grzyby)"
            price="250"
          />
          <PricingElement
            name="Pakiet zdrowy przewód pokarmowy (pasożyty, pierwotniaki, grzyby, bakterie)"
            price="180"
          />
          <PricingElement
            name="Pakiet zdrowa tarczyca (zaburzenia hormonów, obciążenie metalami cieżkimi, niedobory minerałów i witamin, pasożyty, grzyby)"
            price="200"
          />
        </div>
      </section>
    </div>
  );
}

export default Pricing;
