import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import borelioza from "../../assets/kleszcz.webp"

function BoreliosisPage() {
  return (
    <>
      <Helmet>
        <title>Co z tą boreliozą? | Biorezo-med</title>
        <meta
          name="description"
          content="Borelioza to trudna do zdiagnozowania i leczenia choroba przenoszona przez kleszcze i inne owady. Dowiedz się, jak skutecznie diagnozować i leczyć boreliozę oraz jakie oferujemy testy i terapie."
        />
        <meta
          name="keywords"
          content="borelioza, diagnozowanie boreliozy, leczenie boreliozy, testy boreliozy, terapie boreliozy"
        />
        <meta property="og:title" content="Co z tą boreliozą? | Biorezo-med" />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <article className="left-side xl:w-[60%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
              Co z tą boreliozą?
            </h1>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Borelioza to trudna do zdiagnozowania i leczenia choroba. Krętki
              boreli są przenoszone nie tylko przez kleszcze, ale też inne
              owady, w tym meszki. Objawia się przede wszystkim bólem stawów,
              głowy, mięśni, ogólnym osłabieniem, gorączką niewiadomego
              pochodzenia, zaburzeniami płynącymi z układu nerwowego. Niestety
              bakterie krążą w układzie krwionośnym tylko przez 1-2 miesiące od
              momentu zarażenia. Potem "chowają się" w narządach, w bytujących w
              organizmie pasożytach, grzybach czy innych patogenach. Przez to są
              bardzo trudne do wykrycia w standardowych badaniach i do leczenia
              antybiotykami.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nasza pomoc obejmuje następujące elementy:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Testy:</h2>
                <ul>
                  <li>
                    - Na boreliozę i koinfekcje (ponad 60 rodzajów boreliozy,
                    bartonelli, babeszjozy)
                  </li>
                  <li>
                    - Na pasożyty, grzyby, bakterie i wirusy, w których może być
                    ukryta borelioza
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Terapie:</h2>
                <p className="text-slate-600 xl:px-3">
                  Usuwanie wykrytych uprzednio krętek borelii, pasożytów oraz
                  bakterii.
                </p>
              </li>
            </ul>
            <div className="photoAndTextContainer mt-5 mb-10">
              <figure className="w-full p-5">
                <img
                  src={borelioza}
                  alt="Kleszcz siedzący na białym kwiatku"
                  className="w-full h-auto"
                  loading="lazy"
                />
              </figure>
              <section className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Borelioza może mieć poważny wpływ na samopoczucie chorego.
                  Choroba ta może powodować bóle i sztywność stawów, bóle głowy,
                  mięśni, ogólne osłabienie i gorączkę, co może prowadzić do
                  znacznego obniżenia jakości życia i zdolności do wykonywania
                  codziennych czynności. Ponadto, zaburzenia układu nerwowego,
                  takie jak zawroty głowy, bóle głowy, problemy z pamięcią i
                  koncentracją, a także depresja i lęk, mogą dodatkowo pogorszyć
                  samopoczucie.
                </p>
                <p className="mt-5">
                  Wszystko to może mieć wpływ na relacje z rodziną i
                  przyjaciółmi, a także na zdolność do wykonywania pracy i
                  prowadzenia aktywnego trybu życia. Dlatego też ważne jest, aby
                  osoby z podejrzeniem boreliozy otrzymały szybką i odpowiednią
                  opiekę medyczną.
                </p>
              </section>
            </div>
          </article>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h2>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default BoreliosisPage;
