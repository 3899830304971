import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import infekcje from "../../assets/infekcje.webp"

function InfectionPage() {
  return (
    <>
      <Helmet>
        <title>Jak pokonać infekcje? | Biorezo-med</title>
        <meta
          name="description"
          content="Dowiedz się, jak skutecznie pokonać infekcje. Nasza oferta obejmuje testy na wirusy, bakterie, pasożyty i grzyby, oraz terapie wzmacniające narządy. Zadbaj o zdrowie z Biorezo-med."
        />
        <meta
          name="keywords"
          content="infekcje, testy na infekcje, terapie infekcji, wirusy, bakterie, pasożyty, grzyby, zdrowie"
        />
        <meta
          property="og:title"
          content="Jak pokonać infekcje? | Biorezo-med"
        />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <article className="left-side xl:w-[60%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
              Jak pokonać infekcje?
            </h1>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Ludzki organizm narażony jest na coraz większe ilości toksyn w
              żywności, wodzie i powietrzu, co powoduje, że jego naturalna
              wytrzymałość jest często przeciążona. Rezultatem jest spadek
              odporności i częste zachorowania. Leki tylko łagodzą objawy
              choroby, ale aby trwale zapobiegać nawrotom infekcji, konieczne
              jest znalezienie przyczyny. Może to być spowodowane przez obecność
              pasożytów, grzybów, bakterii, takich jak borelioza, osłabienie
              poszczególnych narządów, takich jak śledziona czy nerki, lub
              blokady energetyczne. W celu uzyskania pełnego zdrowia, konieczne
              jest zadbanie o zdrowe odżywianie i styl życia, a także regularne
              wykonywanie badań profilaktycznych i w razie potrzeby wsparcie
              terapeutyczne.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nasza pomoc obejmuje następujące elementy:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Testy:</h2>
                <ul>
                  <li>- Na wirusy, bakterie, pasożyty, grzyby</li>
                  <li>- Na alergeny</li>
                </ul>
              </li>
              <li>
                <h2 className="font-bold xl:px-3 text-slate-800">Terapie:</h2>
                <p className="text-slate-600 xl:px-3">
                  Regulujące oraz wzmacniające narządy.
                </p>
              </li>
            </ul>
            <figure className="photoAndTextContainer mt-5 mb-10">
              <img
                src={infekcje}
                alt="Osoba chora z termometrem trzymająca cytrynę i termofor"
                className="w-full h-auto"
                loading="lazy"
              />
              <figcaption className="text-slate-600 text-center mt-2">
                Przewlekłe choroby negatywnie wpływają na samopoczucie
              </figcaption>
              <section className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Powikłania przewlekłych infekcji mogą mieć negatywny wpływ na
                  wiele aspektów zdrowia. Mogą powodować chroniczne zmęczenie,
                  utratę siły i wytrzymałości, bóle głowy, bóle stawów, bóle
                  mięśni, zaburzenia snu, zaburzenia nastroju, a nawet depresję.
                  Przewlekłe infekcje także mogą powodować poważne uszkodzenia
                  narządów, takich jak serce, wątroba, nerki, a nawet mózg.
                </p>
                <p className="mt-5">
                  W przypadku braku odpowiedniego leczenia, infekcje te mogą
                  również prowadzić do poważnych chorób, takich jak cukrzyca,
                  choroby układu krążenia, choroby autoimmunologiczne i inne
                  poważne schorzenia. Dlatego tak ważne jest, aby szukać pomocy
                  medycznej w przypadku wystąpienia objawów przewlekłych
                  infekcji i stosować odpowiednie leczenie, aby uniknąć
                  powikłań.
                </p>
              </section>
            </figure>
          </article>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h2>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/borelioza">
                    <p className="hover:font-medium">Borelioza</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default InfectionPage;
