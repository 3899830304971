import React from 'react'

import {FaFacebook, FaInstagram, FaTwitter} from "react-icons/fa";

function Footer() {
  return (
    <div className='w-full text-xs md:text-md pt-24 bg-[#262E33] text-gray-300 py-y px-2'>
    <div className='w-[80%] md:w-[60%] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8 max-w-[1720px]'>
        <div className='flex flex-col justify-end'>
            <h6 className='font-bold uppercase'>Testy</h6>
            <ul>
                <li className='py-1'>Alergie</li>
                <li className='py-1'>Pasożyty</li>
                <li className='py-1'>Grzyby</li>
                <li className='py-1'>Borelioza</li>
                <li className='py-1'>Minerały i witaminy</li>
            </ul>
        </div>
        <div>
            <h6 className='font-bold uppercase'>Terapie</h6>
            <ul>
                <li className='py-1'>Odchudzające</li>
                <li className='py-1'>Antynikotynowe</li>
                <li className='py-1'>Zdrowotne</li>
                <li className='py-1'>Odczulające</li>
            </ul>
        </div>
        <div>
            <h6 className='font-bold uppercase'>Pakiety</h6>
            <ul>
                <li className='py-1'>Dla przedszkolaka</li>
                <li className='py-1'>Zdrowy przewód pokarmowy</li>
                <li className='py-1'>Zdrowa tarczyca</li>
            </ul>
        </div>
        <div>
            <h6 className='font-bold uppercase'>Aparatura</h6>
            <ul>
                <li className='py-1'>BICOM Optima</li>
                <li className='py-1'>Certyfikacja</li>
                <li className='py-1'>REGUMED</li>
            </ul>
        </div>
        <div className='col-span-2 pt-8 md:pt-2'>
            <p className='font-bold uppercase'>Zapisz się do newslettera</p>
            <p className='py-4'>Najnowsze newsy, artykuły i zasoby, wysyłane do Ciebie mailem.</p>
            <form className='flex flex-col sm:flex-row'>
                <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter email..'/>
                <button className='p-2 mb-4 hover:scale-105 duration-300'>Subscribe</button>
            </form>
        </div>
    </div>

    <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
    <p className='py-4'>2022 JSTAgency. All rights reserved</p>
    <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
        <FaFacebook className='hover:cursor-pointer hover:text-[#3F65AD] hover:bg-white rounded-full'/>
        <FaInstagram className='hover:cursor-pointer hover:text-[#CA3D72]'/>
        <FaTwitter className='hover:cursor-pointer hover:text-[#2FA1F2]'/>
    </div>
    </div>
</div>
  )
}

export default Footer