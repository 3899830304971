import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import skora from "../../assets/problemy_skorne.webp"

function SkinPage() {
  return (
    <>
      <Helmet>
        <title>Problemy skórne i biorezonans | Biorezo-med</title>
        <meta
          name="description"
          content="Skórne problemy takie jak wysypki, pokrzywki czy liszaje mogą być skutecznie leczone za pomocą biorezonansu. Dowiedz się, jak nasze testy i terapie mogą pomóc w identyfikacji i eliminacji przyczyn problemów skórnych."
        />
        <meta
          name="keywords"
          content="problemy skórne, wysypki, pokrzywki, liszaje, biorezonans, testy alergiczne, terapia skórna, zdrowie skóry"
        />
        <meta
          property="og:title"
          content="Problemy skórne i biorezonans | Biorezo-med"
        />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <div className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <section className="left-side xl:w-[60%]">
            <header>
              <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
                Problemy skórne i biorezonans
              </h1>
            </header>
            <section className="content">
              <p className="md:p-4 p-2 text-slate-600 tracking-wide">
                Skórne problemy, takie jak wysypki, pokrzywki czy liszaje, stają
                się coraz powszechniejsze. Zamiast jedynie łagodzić objawy za
                pomocą maści, warto rozważyć testy biorezonansem, które mogą
                pomóc w ustaleniu źródła problemów skórnych. Najczęstszymi
                przyczynami są nietolerancje pokarmowe, które mogą być trudne do
                zidentyfikowania, ponieważ objawy mogą występować kilka godzin
                po kontakcie z alergenem.
              </p>
              <p className="md:p-4 p-2 text-slate-600 tracking-wide">
                Nasza oferta obejmuje:
              </p>
              <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
                <li>
                  <h3 className="font-bold xl:px-3 text-slate-800">Testy:</h3>
                  <ul className="text-slate-600 xl:px-3">
                    <li>- Na alergeny</li>
                    <li>- Na pasożyty oraz grzyby</li>
                  </ul>
                </li>
                <li>
                  <h3 className="font-bold xl:px-3 text-slate-800">Terapie:</h3>
                  <p className="text-slate-600 xl:px-3">
                    Odczulające, pomagające wyeliminować źródło problemów
                    skórnych z organizmu.
                  </p>
                </li>
              </ul>
              <figure className="photoAndTextContainer mt-5 mb-10">
                <img
                  src={skora}
                  alt="Kobieta drapiąca się po ramieniu"
                  className="w-full h-auto"
                  loading="lazy"
                />
                <figcaption className="text-slate-600 text-center mt-2">
                  Brak odpowiedniego leczenia problemów skórnych może prowadzić
                  do poważniejszych konsekwencji, takich jak rozwijanie się
                  infekcji, powstawanie blizn, a także wpłynąć na samoocenę i
                  jakość życia. Terapia biorezonansem identyfikuje przyczyny
                  problemów skórnych i skutecznie je eliminuje.
                </figcaption>
              </figure>
            </section>
          </section>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <header>
                <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                  Zobacz także:
                </h2>
                <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              </header>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/borelioza">
                    <p className="hover:font-medium">Borelioza</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default SkinPage;
