import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import palenie from "../../assets/rzucanie_palenia.webp"

function SmokingPage() {
  return (
    <>
      <Helmet>
        <title>Jak Rzucić Palenie? | Biorezo-med</title>
        <meta
          name="description"
          content="Zastanawiasz się, jak rzucić palenie? Oferujemy skuteczną terapię antynikotynową metodą biorezonansu. Dowiedz się więcej o korzyściach płynących z rzucenia palenia i naszej ofercie terapii."
        />
        <meta
          name="keywords"
          content="jak rzucić palenie, terapia antynikotynowa, biorezonans, rzucenie palenia, zdrowie, nikotynizm"
        />
        <meta property="og:title" content="Jak Rzucić Palenie? | Biorezo-med" />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <article className="left-side xl:w-[60%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
              Jak rzucić palenie?
            </h1>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nikotynizm jest powszechnie rozpowszechnionym problemem, a wiele
              osób sięga po nikotynę, aby radzić sobie ze stresem. Mimo że aż
              80% palaczy deklaruje chęć rzucenia nałogu, często niestety
              bezskutecznie. Terapia antynikotynowa metodą biorezonansu trwa
              zaledwie 30 minut i gwarantuje skuteczne rzucenie palenia przez
              99% pacjentów już po pierwszej sesji. W przypadku potrzeby,
              terapię można powtórzyć po upływie 6-7 dni.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nasza pomoc obejmuje następujące elementy:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <div>
                  <h2 className="font-bold xl:px-3 text-slate-800">Terapie:</h2>
                  <ul className="text-slate-600 xl:px-3">
                    <li>- Wyciszenie głodu nikotynowego</li>
                    <li>- Usunięcie toksyn</li>
                    <li>- Łagodzenie procesów zapalnych w płucach</li>
                    <li>- Wyciszenie układu nerwowego</li>
                    <li>- Przywrócenie równowagi energetycznej organizmu</li>
                  </ul>
                </div>
              </li>
            </ul>
            <figure className="photoAndTextContainer mt-5 mb-10">
              <img
                src={palenie}
                alt="Mężczyzna przecinający papierosa nożyczkami"
                className="w-full h-auto"
                loading="lazy"
              />
              <figcaption className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Palenie papierosów wiąże się z wieloma poważnymi powikłaniami
                  zdrowotnymi, takimi jak choroby układu krążenia, nowotwory,
                  choroby płuc i układu oddechowego, a także zwiększa ryzyko
                  zaburzeń psychicznych i hormonalnych. Palenie jest również
                  głównym czynnikiem ryzyka w wielu chorobach, takich jak
                  miażdżyca, choroba niedokrwienna serca, cukrzyca i choroba
                  Alzheimera.
                </p>
                <p className="mt-5">
                  W konsekwencji może prowadzić do przedwczesnej śmierci.
                  Dlatego ważne jest, aby zrobić wszystko, co w naszej mocy, aby
                  rzucić palenie i zadbać o swoje zdrowie.
                </p>
              </figcaption>
            </figure>
          </article>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h2>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/przewlekle-zmeczenie">
                    <p className="hover:font-medium">Przewlekłe zmęczenie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/borelioza">
                    <p className="hover:font-medium">Borelioza</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default SmokingPage;
