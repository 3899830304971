import React from "react";
import { Helmet } from "react-helmet";
import AddressTopInfo from "../mainPage/AddressTopInfo";
import { Link as OuterLink } from "react-router-dom";
import Footer from "../mainPage/Footer";

import zmeczenie from "../../assets/zmeczenie.webp"

function TiredPage() {
  return (
    <>
      <Helmet>
        <title>Jak Odzyskać Siły? | Biorezo-med</title>
        <meta
          name="description"
          content="Czujesz chroniczne zmęczenie? Dowiedz się, jak biorezonans może pomóc w odzyskaniu energii. Nasza terapia identyfikuje przyczyny zmęczenia i wspiera powrót do pełni sił."
        />
        <meta
          name="keywords"
          content="przewlekłe zmęczenie, biorezonans, terapia zmęczenia, zdrowie, energia"
        />
        <meta property="og:title" content="Jak Odzyskać Siły? | Biorezo-med" />
      </Helmet>
      <AddressTopInfo />
      <main className="w-full flex">
        <section className="serviceContainer p-2 xl:w-[75%] flex mx-auto justify-between">
          <article className="left-side xl:w-[60%]">
            <h1 className="md:text-7xl text-6xl md:pt-7 p-2 font-thin text-[#05B2DC]">
              Jak odzyskać siły?
            </h1>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Jeśli odczuwasz chroniczne zmęczenie, senność i brak energii, to
              twoje ciało sygnalizuje, że walczy z czymś, co pochłania dużo sił
              i energii. Przyczyny tego stanu mogą być różne, od pasożytów i
              grzybów po stres, blokady energetyczne i zaburzenia w
              funkcjonowaniu poszczególnych narządów. Biorezonans pomaga
              zidentyfikować przyczynę twojego złego samopoczucia, usunąć ją i
              pozwolić ci powrócić do pełni życia.
            </p>
            <p className="md:p-4 p-2 text-slate-600 tracking-wide">
              Nasza pomoc obejmuje następujące elementy:
            </p>
            <ul className="list-disc list-outside xl:ml-[8%] ml-[7%] leading-loose">
              <li>
                <div>
                  <h2 className="font-bold xl:px-3 text-slate-800">Testy:</h2>
                  <ul className="text-slate-600 xl:px-3">
                    <li>- Na pasożyty oraz grzyby</li>
                    <li>- Na bakterie, wirusy i boreliozę</li>
                    <li>- Wydolności organów</li>
                  </ul>
                </div>
              </li>
              <li>
                <div>
                  <h2 className="font-bold xl:px-3 text-slate-800">Terapie:</h2>
                  <ul className="text-slate-600 xl:px-3">
                    <li>- Wzmacniające organy</li>
                    <li>- Usuwające blokady energetyczne</li>
                    <li>- Dla osób pracujących przy komputerze</li>
                  </ul>
                </div>
              </li>
            </ul>
            <figure className="photoAndTextContainer mt-5 mb-10">
              <img
                src={zmeczenie}
                alt="Zmęczony mężczyzna leżący przed otwartym laptopem"
                className="w-full h-auto"
                loading="lazy"
              />
              <figcaption className="w-full p-5 md:p-4 text-slate-600 tracking-wide">
                <p>
                  Przewlekłe zmęczenie może mieć poważne konsekwencje dla
                  zdrowia i jakości życia. Może prowadzić do obniżenia nastroju,
                  trudności w koncentracji, zaburzeń snu i braku energii. W
                  konsekwencji może dojść do spadku produktywności w pracy i
                  trudności w codziennym funkcjonowaniu. Długotrwałe zmęczenie
                  może również prowadzić do poważniejszych problemów
                  zdrowotnych, takich jak choroby serca, depresja i inne
                  zaburzenia emocjonalne.
                </p>
                <p className="mt-5">
                  Terapia biorezonansem może pomóc w radzeniu sobie z
                  przewlekłym zmęczeniem poprzez usunięcie przyczyn tego stanu.
                  Biorezonans wykorzystuje specjalne urządzenie, które generuje
                  fale elektromagnetyczne o określonej częstotliwości, aby
                  wywołać reakcję biologiczną i pomóc w usunięciu toksyn i
                  innych substancji, które mogą powodować zmęczenie. Biorezonans
                  może również pomóc w regulacji funkcji organizmu, zwiększeniu
                  poziomu energii i poprawie nastroju.
                </p>
              </figcaption>
            </figure>
          </article>
          <aside className="right-side w-[30%] bg-[#033860] xl:flex flex-col h-fit mt-10 rounded-3xl sticky top-10 mb-10 hidden">
            <div className="w-[90%] p-8">
              <h2 className="md:text-4xl text-3xl p-2 font-thin text-white">
                Zobacz także:
              </h2>
              <div className="w-[50px] h-[3px] rounded-full bg-[#F9F871] ml-3 mt-2"></div>
              <ul className="text-white font-thin">
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/problemy-skorne">
                    <p className="hover:font-medium">Problemy skórne</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/dysfunkcje-organow">
                    <p className="hover:font-medium">Dysfunkcje organów</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/borelioza">
                    <p className="hover:font-medium">Borelioza</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/odchudzanie">
                    <p className="hover:font-medium">Odchudzanie</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta/infekcje">
                    <p className="hover:font-medium">Przewlekłe infekcje</p>
                  </OuterLink>
                </li>
                <li className="border-b-[1px] border-zinc-500 w-full p-4 hover:cursor-pointer hover-underline-animation">
                  <OuterLink to="/oferta">
                    <p className="hover:font-medium">Pełna oferta</p>
                  </OuterLink>
                </li>
              </ul>
            </div>
          </aside>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default TiredPage;
