import React from "react";
import { FaRegClock, FaPhoneAlt, FaMapMarkedAlt } from "react-icons/fa";

function AddressTopInfo() {
  return (
    <div className="main-container w-full bg-[#033860] flex md:border-none border-b border-b-slate-400 pb-1">
      <div className="three-elements-container md:text-md text-xs flex justify-between md:py-1 w-[80%] mx-auto flex-col xl:flex-row">
        <div className="info-element flex p-1 text-md text-[#f5fbf9] maven-font mx-auto">
          <div
            className="info-element-icon p-1 text-[#05B2DC]"
            title="Godziny otwarcia"
          >
            <FaRegClock />
          </div>
          <div className="info-element-type px-2">
            <time dateTime="08:00">Poniedziałek - Piątek</time>
          </div>
          <div className="info-element-details opacity-60">
            <time dateTime="18:00">08:00 - 18:00</time>
          </div>
        </div>

        <div className="info-element flex p-1 text-md text-[#f5fbf9] maven-font mx-auto">
          <div
            className="info-element-icon p-1 text-[#05B2DC]"
            title="Telefon kontaktowy"
          >
            <FaPhoneAlt />
          </div>
          <div className="info-element-type px-2">
            <a href="tel:+48606243641" className="text-[#f5fbf9]">
              606 243 641
            </a>
          </div>
          <div className="info-element-details opacity-60">
            <a
              href="mailto:a.wlodarczyk78@gmail.com"
              className="text-[#f5fbf9]"
            >
              a.wlodarczyk78@gmail.com
            </a>
          </div>
        </div>

        <div className="info-element flex py-1 text-md text-[#f5fbf9] maven-font mx-auto">
          <div
            className="info-element-icon p-1 text-[#05B2DC]"
            title="Lokalizacja"
          >
            <FaMapMarkedAlt />
          </div>
          <address className="info-element-type px-1 not-italic">
            ul. Elsnera 19, gab. 15
          </address>
          <div className="info-element-details opacity-60">
            Łódź, Przychodnia Batory
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressTopInfo;
